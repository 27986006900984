const fr = {
    common: {
        ok: "OK !",
        cancel: "Annuler",
        back: "Retour",
        estimate: "Estimation",
        search: "Rechercher",
        skip: "Passer",
        next: "Suivant",
        previous: "Précédent",
        finish: "Terminer",
    },
    units: {
        day: "j",
        speed: "Vitesse",
        pace: "Allure",
        distance: "Distance",
        time: "Temps",
        totalTime: "Temps total",
        pace100: "Allure",
        "percent-pace": "% allure",
        kilometer: " km",
        meter: " m",
        mile: " mi",
        yard: " y",
    },
    tourGuide: {
        masInput: "Entrez votre vitesse ou VMA",
        unit: "Changez l'unité de mesure (mêtrique ou impérial)",
        effortCursor: "Faites varier votre effort (de 50% à 150%)",
        distanceInput: "Entrez une distance personnalisée pour visualiser le temps en fonction de votre allure",
        timeInput: "Entrez un temps souhaité : l'allure sera calculée automatiquement en fonction de la distance entrée",
        raceTime: "Ce temps est basé sur les algorithmes de Pacevisor grâce à votre allure actuelle et le profil de la course. Il peut être affiné dans la fiche d'analyse de la course en appuyant sur l'évènement.",
        raceTimeSmall: "Ce temps est basé sur les algorithmes de Pacevisor grâce à votre allure actuelle et le profil de la course.",
    },
    sports: {
        title: "Disciplines",
        run: "Course",
        bike: "Vélo",
        swim: "Nage",
        transition: "Transition",
    },
    errors: {
        invalidEmail: "Adresse email invalide.",
    },
    errorScreen: {
        title: "Something went wrong!",
        friendlySubtitle: "This is the screen that your users will see in production when an error is thrown. You'll want to customize this message (located in `app/i18n/en.ts`) and probably the layout as well (`app/screens/ErrorScreen`). If you want to remove this entirely, check `app/app.tsx` for the <ErrorBoundary> component.",
        reset: "RESET APP",
    },
    emptyStateComponent: {
        generic: {
            heading: "So empty... so sad",
            content: "No data found yet. Try clicking the button to refresh or reload the app.",
            button: "Let's try this again",
        },
    },
    triathlon: {
        mode: "Mode triathlon",
    },
    homeScreen: {
        segmented: {
            single: "Course simple",
            multi: "Triathlon",
        },
    },
    runScreen: {
        seeAll: "Voir tout",
        favorites: "Mes favoris",
        removeFavoriteTitle: "Supprimer des favoris",
        removeFavoriteDescription: "Voulez-vous vraiment supprimer cette course de vos favoris ?",
        nearBy: "Aux alentours",
        races: "Les courses",
        needLocationTitle: "Utilisation de votre localisation",
        needLocationDescription: "Votre position est utilisée pour trouver les courses autour de la position. Cette information n'est pas conservée après la fermeture de l'application. Soyez assuré que nous ne stockons aucune information de localisation en dehors de l'appareil.",
        geolocationTitle: "Aux alentours",
        geolocationDescription: "Cliquez ici pour activer la géolocalisation et trouver les courses autour de chez moi.",
        geolocationButton: "J'y vais",
    },
    bikeScreen: {},
    swimScreen: {},
    settingsScreen: {
        title: "Paramètres",
        isMetricSystem: "Utilisez-vous le système métrique ?\n(ex : kilomètre, mètre)",
    },
    distancesScreen: {
        title: "Distances personnalisées",
        local: "Courses locales",
    },
    racesScreen: {
        title: "Fiches de course",
        addRace: "Votre course n'y est pas ? Ajoutez les informations 📝",
        cardTitle: "Tu veux trouver des courses ?",
        cardDescription: "Pacevisor te propose des analyses pour mieux te préparer. Elles sont basées sur ton allure et le parcours de la course.",
        cardButton: "Trouver des courses",
        distance: {
            zero: "Distance",
            one: "Distance : %{count} km",
            other: "Distance : %{count} km",
        },
        location: "Localisation",
    },
    raceScreen: {
        title: "Course",
        "seo-description": `Découvrez les informations de la course %{name} de %{distance} km à %{place}. Elle aura lieu le %{date}. Vous pouvez aussi estimer votre temps de course en fonction de votre vitesse (km/h) ou de votre allure (min/km).`,
        climberNote: "Aptitude à la montée : ",
        descenderNote: "Aptitude à la descente : ",
        abilityNote: "(> 1 est bon, < 1 est mauvais)",
        totalDistance: `%{distance} km`,
        elevation: "%{value} m",
        elevationAvg: "moy",
        pace: "%{value} %{unit}",
        editMyInfo: "Changer mon allure",
        moreOptions: "Plus de réglages",
        weight: "Mon poids (en kg)",
        seeWebsite: "Voir le site",
        profile: "Profil de course",
        graphSubtitle: "Maintenez pour afficher un segment dynamiquement sur le graph et sur la carte",
        paceTitle: "Allure",
        completeAnalysis: "Voir l'analyse complète sur ",
        gpxFile: "Fichier GPX",
        computeRace: "Calculer mon temps",
        orDownload: "ou télécharger l'app",
        seeMore: "Voir tout le roadbook",
    },
    editRaceScreen: {
        title: "Modifier votre course",
        add: "Ajouter une course d'entrainement",
        removeTitle: "Supprimer la course",
        removeDescription: "Voulez-vous vraiment supprimer cette course de votre téléphone ?",
    },
    mapScreen: {
        title: "Carte des courses",
    },
    storybook: {
        placeholder: "Placeholder",
        field: "Champs",
    },
    pricing: {
        title: "Tarif",
    },
    landing: {
        "seo-title": "Calculer vos temps et allure en course à pied 10 km semi-marathon",
        "seo-description": "L'application permet de convertir des temps de course à pied sur les distances 10 km ou semi-marathon. Vous pouvez ajuster la distance, la vitesse (km/h) et même le rythme (min/km) et tout se met à jour automatiquement. Une estimation de votre rythme est visible sur d'autres distances, et dans d'autres sports comme le vélo ou la natation.",
        organizer: "Organisateur",
        organizerUrl: "/organisateur",
        paceTable: "Tableau simplifié des allures",
        paceTableUrl: "/tableau-allures",
        raceUrl: "/races",
        openInApp: "Ouvrir dans l'application",
        fullTitle: "Pacevisor, votre convertisseur temps course",
        download: "Télécharger l'app",
        title: "Pacevisor",
        subtitle: "Objectif. Conversion. Performance.",
        description: `Pour les athlètes polyvalents##Que vous pratiquiez la course, la natation ou le cyclisme, notre application s'adapte à toutes les distances, y compris les triathlons. Visualisez vos performances en miles ou en yards, un atout précieux pour vos entraînements à l'international.

Modulation d'allure avec précision##Ajustez votre allure en temps réel avec notre slider interactif. Que ce soit pour une séance d'entraînement spécifique ou pour estimer vos temps sur différentes distances, notre fonctionnalité de modulation d'allure vous permet de visualiser instantanément l'impact de chaque ajustement. Par exemple, découvrez ce que représente 90% de 4:30 min/km sur une distance de 3 km (ça donne 15 minutes !).

Adaptation dynamique du temps et de la vitesse##Modifiez le temps pour une distance donnée et observez l'ajustement automatique de votre vitesse. Cette fonctionnalité est idéale pour planifier des objectifs de course ou pour adapter votre entraînement à des scénarios de course spécifiques.

Nouveauté pour les coureurs de trail##Profitez de notre fonctionnalité avancée de calcul de temps de segment, adaptée aux caractéristiques uniques des trails. En tenant compte de vos aptitudes personnelles en montée et en descente, cette fonctionnalité vous permet d'optimiser chaque étape de votre parcours en trail, vous offrant ainsi une analyse détaillée et personnalisée pour améliorer vos performances.`,
        racesTitle: "Trouvez votre ligne d'arrivée",
        tile1: "Bibliothèque de courses",
        tile1Description: `Explorez une vaste collection de parcours de courses.
Filtrez et trouvez des courses parmi une variété de distances et de dénivelés pour vous entraîner et pour performer lors de vos prochaines compétitions.`,
        tile2: "Prédiction de l'allure",
        tile2Description: "Bénéficiez des algorithmes avancés de Pacevisor pour prédire votre allure de course en fonction de vos performances passées, de la topographie du parcours et de vos objectifs actuels.",
        tile3: "Convertisseur de temps et de distance",
        tile3Description: 'Grâce au mode "Entraînement", convertissez facilement le temps en distance et vice versa avec notre outil intuitif. Idéal pour planifier vos entraînements et gérer vos objectifs de course.',
        tile4: "Roadbook personnalisé",
        tile4Description: "Récupérez un roadbook sur mesure pour chaque course, détaillant votre stratégie de course, les points de ravitaillement et les changements d'allure prévus.",
        tile5: "Gratuite et sans pub !",
        tile5Description: "Téléchargez l'application gratuitement et profitez d'une expérience sans publicité. Seuls les organisateurs payent pour ajouter leurs courses à la bibliothèque. Disponible sur iOS et Android.",
    },
    social: {
        help: "Besoin d'aide ?",
        switchLang: "English version?",
        switchLangUrl: "/en",
        privacyPolicy: "Politique de confidentialité",
    },
    table: {
        "seo-title": "Tableau des allures de course",
        "seo-description": "Tableau des allures pour voir facilement le temps de course pour les principales épreuves en fonction de votre vitesse (km/h et min/km) sur 5 km, semi-marathon ou marathon.",
        info: "Ce tableau simplifié vous permet de visualiser les temps de course en fonction de votre vitesse (km/h et min/km). Téléchargez l'application pour obtenir des estimations personnalisées.",
        kmh: "Kilomètres par heure",
        "min-km": "Minutes par kilomètre",
        "half-marathon": "Semi-marathon",
    },
    platform: {
        title: "Plateforme",
        subtitle: "Transformez l'expérience des événements avec Pacevisor",
        intro: `Intégré directement à votre plateforme d'événements, Pacevisor permet aux organisateurs d'enrichir l'expérience de chaque coureur avec des prévisions de course personnalisées.

Pacevisor se fond dans l'interface de votre plateforme pour une expérience utilisateur fluide. Offrez un service supplémentaire qui différencie votre plateforme sur le marché.`,
        addPluginTitle: "J'ajoute le plugin sur ma plateforme",
        queryParamsTitle: "L'intégration du plugin se fait par iframe, où il est possible de customiser l'interface par des paramètres d'URL à ajouter à la base url :",
    },
    new: {
        addRace: "Ajouter une course",
        addRaceUrl: "/new",
        "seo-description": "Ajoutez une course à Pacevisor pour l'intégrer à l'application.",
        fields: {
            id: "Identifiant de l'évènement",
            active: "Actif",
            title: "Titre de l'évènement",
            type: "Type de course (run, bike, swim)",
            gpxUrl: "URL du fichier GPX (optionnel)",
            baseSpeed: "Vitesse connue du participant (unité en kilomètre par seconde, optionnel)",
            weight: "Poids du participant (en kg, optionnel)",
            distance: "Distance de la course",
            latitude: "Latitude (optionnel)",
            longitude: "Longitude (optionnel)",
            place: "Lieu de l'évènement (optionnel)",
            email: "Votre email",
            url: "URL du site de l'évènement, ou de l'inscription (optionnel)",
            date: "Date de l'évènement",
            backgroundColor: "Couleur de fond (hexadécimal, optionnel)",
            textColor: "Couleur du texte (hexadécimal, optionnel)",
            imageUrl: "URL de l'image (optionnel)",
            waypoints: "Points de passage (noms et distances en mètres, optionnel)",
            addWaypoint: "Ajouter",
            removeWaypoint: "Supprimer",
            hideOptional: "Masquer les champs optionnels",
            showOptional: "Afficher plus de configuration",
        },
        submit: "Valider et envoyer",
    },
    ghost: {
        title: "GPX fantôme (beta)",
        description: "Vous vous rappelez du fantôme dans Mario Kart, pour voir votre replay ? Téléchargez le fichier GPX de l'algorithme personnalisé Pacevisor, importez-le dans votre montre connectée et suivez le parcours selon vos objectifs.",
        step1: 'Cliquez sur le bouton "Ghost"',
        description1: "Le mode ghost est un fichier GPX personnalisé pour votre course.",
        step2: "Sélectionnez l'application de votre montre (ex : Garmin)",
        step3: "Sélectionnez le sport (ex : course à pied)",
        step4: "Envoyez l'activité sur votre montre",
        step5: "Sur la montre, sélectionnez le sport",
        step6: "Options > Entraînements",
        step7: "Se mesurer à une activité",
        description7: "Le parcours est prêt à être suivi ! Sur la montre, suivez le fantôme Pacevisor et adaptez votre allure pour atteindre vos objectifs.",
    },
    organizer: {
        subtitle: "Améliorez l'expérience de vos participants avec Pacevisor, le widget indispensable pour tout événement de course, vélo ou nage !",
        "seo-title": "Organisateur d'événement",
        "seo-description": "Utiliser Pacevisor pour votre événement, c'est possible ! Intégrez le widget Pacevisor sur votre site web pour permettre à vos participants de calculer leur temps de course.",
        intro: "Pacevisor est un outil interactif conçu pour aider les coureurs à visualiser et à planifier leur stratégie de course. Une fois que la course a été validée par l'équipe Pacevisor, allez à la prochaine étape !",
        step: "Étape %{step} : %{title}",
        seeRaceTitle: "Visualiser le rendu",
        seeRaceDescription: "Une fois que la course a été validée par l'équipe Pacevisor, vous pouvez visualiser le rendu de la course sur l'application, ou sur le web.",
        seeRaceButton: "Voir la course",
        shareRaceTitle: "Partager sur mes réseaux",
        shareRaceDescription: "Partagez la course sur vos réseaux sociaux pour aider vos participants à se préparer au mieux et renforcer votre communauté.",
        shareRaceButton: "Copier le lien en cliquant ici",
        addMyRaceTitle: "Ajouter ma course dans l'application",
        addWidgetTitle: "Ajouter le widget sur mon site web",
        addWidgetDescription: "Notre widget s'intègre facilement sur votre site et offre une estimation précise du temps de course en fonction de l'allure du coureur. Copiez le code ci-dessous et collez-le dans le code source de votre site web.",
        congratulationsTitle: "Félicitations !",
        congratulationsDescription: "Votre course a été ajoutée avec succès. Vos participants peuvent maintenant visualiser et planifier leur stratégie de course. Merci pour eux !",
        copyPaste: "Copiez-collez ce code sur votre site",
        exampleTitle: "Exemple de widget",
        copied: "Copié !",
        copy: "Copier le code HTML",
    },
    calendar: {
        "seo-title": "Calendrier des évènements",
        "seo-description": "Découvrez les prochains événements de course à pied, vélo ou nage. Ajoutez votre course pour obtenir une estimation de votre temps de course. Toutes les courses à venir y sont.",
        url: "/calendrier",
    },
    faq: {
        faqUrl: "/#faq",
        title: "Questions fréquentes",
        content: `Comment Pacevisor peut-il aider à améliorer mes performances en course ?
Pacevisor vous aide à comprendre et à optimiser votre allure en course, vous permettant de planifier des entraînements et des compétitions plus efficacement.
N'hésitez pas à ajouter des distances personnalisées avec le bouton "+" en dessous de la partie "Estimation" pour adapter l'application à vos besoins.

Comment convertir min/km en km/h pour la course à pied ?
Utilisez notre outil de conversion intégré pour facilement transformer votre allure en min/km en vitesse en km/h.
Par exemple 6 min/km donnera 10 km/h.

Quelle est la meilleure manière d'utiliser Pacevisor pour le cyclisme ?
Pour le vélo, concentrez-vous sur la fonctionnalité de conversion en km/h pour surveiller et ajuster votre vitesse pendant l'entraînement.
Par exemple, insérer 20 km/h pour évaluer vos performances sur différents segments.

Comment Pacevisor peut-il m'aider en natation ?
Utilisez l'app pour convertir et comprendre vos temps en min/100m, un indicateur clé pour les nageurs.
Dans ce sport, les distances sont toujours utilisées en mètres ou yards.

Puis-je utiliser Pacevisor pour des triathlons ?
Absolument, Pacevisor est idéal pour gérer les allures dans les trois disciplines du triathlon. Les temps de transition seront bientôt ajoutés à l'application.

Comment faire varier mon intensité d'entraînement avec Pacevisor ?
Le curseur de modulation vous permet d'ajuster votre Vitesse Maximale Aérobie (VMA) de 50% à 150%. Cela recalculera automatiquement vos allures pour les différents sports.
Par exemple, si votre VMA est de 12 km/h et que vous réglez le slider à 80%, Pacevisor recalculera votre allure pour correspondre à une VMA de 9.6 km/h. Cela vous aide à planifier des entraînements adaptés à différents niveaux d'intensité.

Comment estimer le temps de course pour un nouveau parcours ?
Entrez les détails de votre parcours dans Pacevisor pour obtenir une estimation précise de votre temps de course basée sur votre allure habituelle.
Un curseur vous permet d'adapter votre capacité à monter et descendre pour affiner votre temps, segment par segment.

Comment puis-je planifier mon entraînement avec Pacevisor ?
Utilisez les outils de conversion pour adapter votre allure à différents types d'entraînement, des séances d'endurance aux sprints.

Est-il possible d'ajuster mon rythme en fonction de l'altitude ?
Oui, Pacevisor peut vous aider à ajuster votre allure en fonction des variations d'altitude, essentielles en trail running.

Comment intégrer les données d'événements dans Pacevisor ?
Si vous organisez ou participez à un événement, vous pouvez utiliser Pacevisor pour analyser et partager des profils de course spécifiques.
Allez sur la page Organisateur pour en savoir plus.

Quels sont les avantages de comprendre les conversions d'unités en sport ?
Maîtriser ces conversions vous permet d'affiner votre stratégie d'entraînement et d'améliorer vos performances sportives.`,
    },
};
export default fr;
